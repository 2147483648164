<template>
  <b-sidebar
      :visible="isAddNewSidebarActive"
      bg-variant="white"
      sidebar-class="sidebar-lg"
      id="add-new-record-sidebar"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
      shadow no-header right>
    <template #default="{ hide }">

      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">Yeni Kayıt</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="$emit('update:is-add-new-sidebar-active', false)"/>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

          <validation-provider #default="validationContext" name="İsim" rules="required">
            <b-form-group label="İsim">
              <b-form-input trim placeholder="İsim" v-model="definitionData.name" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Anahtar" rules="required">
            <b-form-group label="Anahtar">
              <b-form-input trim placeholder="Anahtar" v-model="definitionData.key" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Sıra" rules="required">
            <b-form-group label="Sıra">
              <b-form-input trim placeholder="Sıra" v-model="definitionData.sortNumber" :state="getValidationState(validationContext)"/>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Varsayılan" rules="required">
            <b-form-group label="Varsayılan" label-for="isDefault" :state="getValidationState(validationContext)">
              <v-select v-model="definitionData.isDefault" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Dil" rules="required">
            <b-form-group label="Dil" :state="getValidationState(validationContext)">
              <v-select v-model="definitionData.languageId" :options="languageOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider #default="validationContext" name="Durum" rules="required">
            <b-form-group label="Durum" label-for="isActive" :state="getValidationState(validationContext)">
              <v-select v-model="definitionData.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit">Kaydet</b-button>
            <b-button type="button" variant="outline-secondary" @click="$emit('update:is-add-new-sidebar-active', false)">Vazgeç</b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ref, watch} from '@vue/composition-api'
import {required} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import {statusOptions, yesNoOptions} from "@core/utils/filter"

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    definitionBaseData: {
      type: Object,
      required: true,
    },
    selectDefinitionData: {
      type: Object,
      required: true,
    },
    languageOptions: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      required,
    }
  },
  setup(props, {emit}) {

    let editBaseKey = null;
    const blankDefinitionData = {
      languageId: null,
      languageName: '',
      key: '',
      name: '',
      sortNumber: null,
      isDefault: false,
      isActive: true,
    }

    const definitionData = ref(JSON.parse(JSON.stringify(blankDefinitionData)))
    const resetDefinitionData = () => {
      definitionData.value = JSON.parse(JSON.stringify(blankDefinitionData))
    }

    const onSubmit = () => {
      if (props.selectDefinitionData.key === undefined) {
        definitionData.value.languageName = definitionData.value.languageId === 2 ? 'İngilizce' : 'Türkçe'
        props.definitionBaseData.definitions.push(definitionData.value)
      } else {
        props.definitionBaseData.definitions.forEach(function callback(value, index) {
          if (value.key === editBaseKey) {
            value.languageId = definitionData.value.languageId;
            value.languageName = definitionData.value.languageId === 2 ? 'İngilizce' : 'Türkçe'
            value.key = definitionData.value.key
            value.name = definitionData.value.name
            value.sortNumber = definitionData.value.sortNumber
            value.isDefault = definitionData.value.isDefault
            value.isActive = definitionData.value.isActive
          }
        })
      }
      emit('update:is-add-new-sidebar-active', false)
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDefinitionData)

    watch(() => props.isAddNewSidebarActive, (dataValue) => {
      if (dataValue) {
        if (props.selectDefinitionData.key !== undefined) {
          definitionData.value = props.selectDefinitionData
          editBaseKey = props.selectDefinitionData.key
        }
      } else {
        resetDefinitionData()
      }
    })

    return {
      definitionData,
      refFormObserver,

      statusOptions,
      yesNoOptions,

      getValidationState,
      resetForm,
      onSubmit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
